<template>
  <div>
    <v-bottom-sheet v-model="shower" v-if="resolutionScreen < 500">
      <v-list dense tile>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ filedata.file_name }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action class="ma-0">
            <v-list-item-icon class="mr-n12">
              <v-icon color="red" @click="$emit('closecurrentonly')">clear</v-icon>
            </v-list-item-icon>
          </v-list-item-action>
        </v-list-item>
        <v-divider class="mx-4"></v-divider>
        <v-list-item @click="fn_selectFunction('preview')" v-if="filedata.type === 'file'">
          <v-list-item-icon>
            <v-icon medium>visibility</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('currentRClick.preview') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="fn_selectFunction('detail')">
          <v-list-item-icon>
            <v-icon medium>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('expiredrightclick.detail') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="fn_selectFunction('showinfolder')">
          <v-list-item-icon>
            <v-icon medium>mdi-folder-open-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('expiredrightclick.showinfolder') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="fn_selectFunction('setexpire')" v-if="(filedata.file_permission_2 === '04' || filedata.file_permission_2 === '05' || filedata.file_permission_2 === '06') || filedata.file_owner_eng == 'Me' || role_level == 'True'">
          <v-list-item-icon>
            <v-icon medium>mdi-folder-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('expiredrightclick.setexpire') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="fn_selectFunction('managefile')" v-if="dataAccountActive.type === 'Business' && (filedata.file_permission_2 === '06' || filedata.file_owner_eng == 'Me' || role_level == 'True')">
          <v-list-item-icon>
            <v-icon medium>mdi-cogs</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('currentRClick.managefile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="fn_selectFunction('delete')" v-if="(filedata.file_permission_2 === '05' || filedata.file_permission_2 === '06') || filedata.file_owner_eng == 'Me' || role_level == 'True'">
          <v-list-item-icon>
            <v-icon medium>delete</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('expiredrightclick.deleteexpire') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>    
    <v-menu v-else v-model="shower" :position-x="AreaX" :position-y="AreaY" top offset-y close-on-click close-on-content-click>
      <v-list dense tile>
        <v-list-item @click="fn_selectFunction('preview')" v-if="filedata.type === 'file'">
          <v-list-item-icon>
            <v-icon medium>visibility</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('currentRClick.preview') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="fn_selectFunction('showinfolder')">
          <v-list-item-icon>
            <v-icon medium>mdi-folder-open-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('expiredrightclick.showinfolder') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="fn_selectFunction('setexpire')" v-if="(filedata.file_permission_2 === '04' || filedata.file_permission_2 === '05' || filedata.file_permission_2 === '06') || filedata.file_owner_eng == 'Me' || role_level == 'True'">
          <v-list-item-icon>
            <v-icon medium>mdi-folder-clock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('expiredrightclick.setexpire') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="fn_selectFunction('managefile')" v-if="dataAccountActive.type === 'Business' && (filedata.file_permission_2 === '06' || filedata.file_owner_eng == 'Me' || role_level == 'True')">
          <v-list-item-icon>
            <v-icon medium>mdi-cogs</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('currentRClick.managefile') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="fn_selectFunction('keydetail')">
          <v-list-item-icon>
            <v-icon medium>mdi-information</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('currentRClick.keydetail') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="fn_selectFunction('delete')" v-if="(filedata.file_permission_2 === '05' || filedata.file_permission_2 === '06') || filedata.file_owner_eng == 'Me' || role_level == 'True'">
          <v-list-item-icon>
            <v-icon medium>delete</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('expiredrightclick.deleteexpire') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <dialogdetailexpiredfile
      :show="opendetailexpiredfile"
      @closedialog="opendetailexpiredfile = false"
      :filedata="newfiledata"
    ></dialogdetailexpiredfile>
    <dialogsetexpiredfile
      :show="opensetexpired"
      @closedialog="opensetexpired = false"
      :filedata="newfiledata"
      @reload="fn_reloadsetexpired()"
    ></dialogsetexpiredfile>
    <previewfile
      :show="openpreviewfile"
      :arrayfile="arrayfile"
      :filedata_1="newfiledata"
      :parentfolder="parentfolder"
      :param_preview="param_preview"
      @closepreviewfile="(openpreviewfile = false), $emit('openDrag')"
      @openpreviewfile="openpreviewfile = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
      @reload="fn_reloadsetexpired()"
    ></previewfile>
    <keydetail
      :show="openkeydetail"
      :filedata="newfiledata"
      :parentfolder="parentfolder"
      @updatedatafile="closeDialogEditname()"
      @closedkeydetail="(openkeydetail = false), $emit('openDrag')"
      @openkeydetail="openkeydetail = true"
      @closeDrag="$emit('closeDrag')"
      @openDrag="$emit('openDrag')"
    ></keydetail>
    <setpermissionfile
      :show="opensetpermissionfile"
      :filedata="newfiledata"
      @closesetPermissionFile="(opensetpermissionfile = false), $emit('reload')"
      @opensetPermissionFile="opensetpermissionfile = true"
      @closeDrag="$emit('closeDrag')"
      @closenoreload="opensetpermissionfile = false"
    ></setpermissionfile>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.js";
import VueCookies from "vue-cookies";
import gb_checkeditonline from "@/globalFunctions/checkinetjv";

const previewfile = () => import("../optional/dialog-preview");
const dialogdetailexpiredfile = () => import("../optional/dialog-detailexpiredfile");
const dialogsetexpiredfile = () => import("../optional/dialog-setexpiredfile");
const keydetail = () => import("../optional/dialog-keydetail");
const setpermissionfile = () => import("../optional/dialog-setpermissionfile");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {
  props: ["show", "AreaX", "AreaY", "filedata","arrayfile","parentfolder","parentfoldername","file","fileLength"],
  components: {
    dialogsetexpiredfile,
    dialogdetailexpiredfile,
    previewfile,
    keydetail,
    setpermissionfile
  },
  data: function() {
    return {
      param_preview:"",
      file:"",
      openpreviewfile:false,
      openkeydetail:false,
      opensetpermissionfile:false,
      newfiledata: "",
      opensetexpired: false,
      opendetailexpiredfile: false,
      percent: 0,
      datetime: "",
      ItemOption: [
        {
          text: "currentRClick.preview",
          select_funtion: "preview",
          icon: "visibility",
        },
        {
          text: "expiredrightclick.showinfolder",
          select_funtion: "showinfolder",
          icon: "mdi-folder-open-outline",
        },
        {
          text: "expiredrightclick.setexpire",
          select_funtion: "setexpire",
          icon: "mdi-folder-clock",
        },
        {
          text: "currentRClick.managefile",
          select_funtion: "managefile",
          icon: "mdi-cogs",
        },
        {
          text: "currentRClick.keydetail",
          select_funtion: "keydetail",
          icon: "mdi-information",
        },
        {
          text: "expiredrightclick.deleteexpire",
          select_funtion: "delete",
          icon: "delete",
        },
      ],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "role_level", "feature_onlyoffice"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    shower: {
      get() {
        if (this.show === true) {
          this.newfiledata = this.filedata;
        }
        return this.show;
      },
      set(value) {
        if (!value) {
          this.$emit("closecurrentonly");
        }
      },
    },
  },
  methods: {
    //emitreload
    fn_reloadsetexpired() {
      this.$emit("reload");
    },
    
    //เลือกฟังก์ชัน
    fn_selectFunction(path) {
      if (path == "setexpire") {
        this.opensetexpired = true;
      } else if (path == "delete") {
        this.$emit("callupdatestatusfile", this.filedata);
      } else if (path == "showinfolder") {
        this.$emit("callshowinfolder", this.filedata);
      } else if (path == "detail") {
        this.opendetailexpiredfile = true;
      }else if (path == "preview"){
        console.log("this.filedata",this.filedata);
        // let checkinet_jv = gb_checkeditonline.checkeditonlineinetjv(this.dataAccountActive.taxid)
        // if(checkinet_jv === -1){
      // let checkinet_jv = VueCookies.get("feature_onlyoffice")
      // if(checkinet_jv === 'false'){
      let checkinet_jv = this.feature_onlyoffice;
        if(checkinet_jv === false){
          this.param_preview = "preview";
        }else{
          if(
            // (['04', '05', '06'].includes(this.filedata.file_permission_2)) &&
            (this.filedata.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(this.filedata.file_type.toLowerCase()) 
            // || 
            // this.filedata.data_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(this.filedata.data_type.toLowerCase()) || 
            // this.file.file_type && ['docx','xlsx','pptx','xlsb','xml','txt','html'].includes(  this.file.file_type.toLowerCase())
            )
          ){
            if(this.filedata.status_expired === '0'){
              this.param_preview = "editfileonline_expired";
            }else{
              this.param_preview = "editfileonline";
            }
          }else{
            this.param_preview = "preview";
          }
        }
        // this.openpreviewfile = true , this.filedata;
        console.log("this.file",this.filedata);
        if( this.filedata.editing_status === 'S'){
          this.openpreviewfile = false;
          Toast.fire({
            icon: "warning",
            title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
          });
        }else if( this.filedata.editing_status === 'Y'){
          if(this.filedata.last_update_name.name_th === 'ฉัน'){
            this.openpreviewfile = true , this.filedata;
          }else{
            this.openpreviewfile = false;
            Toast.fire({
              icon: "warning",
              title: "ไม่สามารถเปิดได้เนื่องจากมีการแก้ไขไฟล์",
            });
          }
        }else{
          this.openpreviewfile = true , this.filedata;
        }
      }else if (path == "keydetail"){
        this.openkeydetail = true;
      }else if (path == "managefile"){
        this.opensetpermissionfile = true, this.file;
      }
    },
    
    fn_zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },
   
   // emit กลับไปหน้า dialog-setexpired
    fn_parentreload() {
      this.$emit("reload");
    },
    fn_previewnewtab(file) {
      let currentfile = JSON.stringify(file);
      sessionStorage.setItem("file_preview_newtab", currentfile);
      let route = this.$router.resolve({ path: "/preview" });
      let arrayfile_ = JSON.stringify(this.arrayfile);
      sessionStorage.setItem("arrayfile", arrayfile_);
      window.open(route.href);
    },
  },
};
</script>

<style></style>
